import { useEffect, useRef, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useLocation, useNavigate } from "react-router-dom";
//Store
import { useDispatch, useTrackedState } from "../../store/store";
//Hooks
import useCurrentSession from '../../hooks/useCurrentSession';
import useExternalElement from "../../hooks/useExternalElement";
import useIsMobile from '../../hooks/useIsMobile';
//Components
import Menu from "../Menu/Menu";

const Header = () => {
  //Store
  const state = useTrackedState();
  const { profile } = state;
  const dispatch = useDispatch();
  //States
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenUserOptions, setIsOpenUserOptions] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  //Hooks
  const navigate = useNavigate();
  const location = useLocation();
  //Custom hooks
  const { isAuth } = useCurrentSession();
  const { isDesktop } = useIsMobile();
  //Refs
  const refSubMenu = useRef(null);

  useEffect(() => {
    const scrollPage = () => {
      const scroll = window.pageYOffset !== 0;
      setIsScroll(scroll);
    }

    window.addEventListener('scroll', scrollPage);
    return () => {
      window.removeEventListener('scroll', scrollPage);
    }
  }, [])


  useEffect(() => {
    setIsOpenMenu(false);
    setIsOpenUserOptions(false);
  }, [isDesktop])

  //Log out function
  const singOut = async () => {
    setIsOpenUserOptions(false);
    await Auth.signOut();
    dispatch({ type: 'SIGN_OUT' });
    navigate('/login');
  }

  //Update the state that controls if the submenu is open or close
  const updateStateSubMenu = (value) => {
    const valueState = typeof value === 'boolean' ? value : !isOpenUserOptions;
    setIsOpenUserOptions(valueState)
  };

  //Update if the menu is open or not in mobile
  const updateStateMenuMobile = (value) => {
    const valueState = typeof value === 'boolean' ? value : !isOpenUserOptions;
    setIsOpenMenu(valueState)
  };

  //Custom hooks
  useExternalElement(() => updateStateSubMenu(false), refSubMenu);


  return (
    <>
      {
        location.pathname !== '/login' && isAuth && (
          <header className={isScroll ? 'sticky' : ''}>
            <div className="flex justify-between items-center mx-auto py-2 px-5 lg:px-10">
              <Link className="text-30 flex items-center text-gray-darkest" to="/"
              >
                <i className="icon-logo mr-3" />
                <p className="text-22 poppins-500 text-gray-darker">Support Console</p>
              </Link>
              {
                isDesktop ? (
                  <>
                    <div className="basis-2/4">
                      <Menu />
                    </div>
                    <div className="flex justify-end relative" ref={refSubMenu}>
                      <button
                        className="user-profile"
                        type="button"
                        onClick={updateStateSubMenu}
                      >
                        <i className="icon-user" />
                        <p>{profile.email}</p>
                      </button>
                      {
                        isOpenUserOptions && (
                          <nav className="header-submenu">
                            <button
                              className="text-left"
                              onClick={singOut}
                            >
                              Sign out
                            </button>
                          </nav>
                        )
                      }
                    </div>
                  </>
                )
                  //Mobile
                  :
                  <>
                    <button
                      className="text-20 pt-1"
                      onClick={() => updateStateMenuMobile(!isOpenMenu)}
                      type="button"
                    >
                      <i className={`icon-${isOpenMenu ? 'close' : 'menu'}`} />
                    </button>
                    <div
                      className={`header-menu-mobile ${isOpenMenu ? 'header-menu-mobile-open' : ''}`}
                    >
                      <div className="header-profile px-5 py-5">
                        <div>
                          <div className="user-profile">
                            <i className="icon-user" />
                            <p>{profile.email}</p>
                          </div>
                        </div>

                        <button
                          className="button-logout"
                          onClick={singOut}
                        >
                          <i className="icon-logout mr-2" />
                          Sign out
                        </button>
                      </div>
                      <hr className="mx-5 my-5" />
                      <Menu
                        onClick={() => { updateStateMenuMobile(false) }}
                      />

                    </div>
                    {
                      isOpenMenu && (
                        <div
                          className="header-canvas-close"
                          onClick={() => updateStateMenuMobile(false)}
                        />
                      )
                    }
                  </>
              }
            </div>
          </header>
        )
      }
    </>
  )
}

export default Header;