const NoMatch = (props) => {
  const {icon, message} = props;

  return (
    <div className="text-center pt-4 lg:pt-5">
      <i className={`icon-${icon} text-140`} />
      <h1 className="text-5xl lg:text-6xl font-normal">{message}</h1>
    </div>
  )
}

export default NoMatch;