import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
//Data
import dataRoutes from "./dataRoutes";
//Hooks
import useIsMobile from '../../hooks/useIsMobile';
//Functions
import generalCallApi from '../../functions/generalCallApi';
import normalizeString from "../../functions/normalizeString";
//Components
import MenuItems from "./MenuItems";

const Menu = (props) => {
  const { onClick = () => { } } = props;
  //State
  const [menuOptions, setMenuOptions] = useState(dataRoutes);
  const [orderManagementOptions, setOrderManagementOptions] = useState([]);

  //Custom hooks
  const { isDesktop } = useIsMobile();
  useEffect(() => {
    getOrderManagementMenu();
    const interval = setInterval(() => {
      getOrderManagementMenu();
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Get queue lists
  const getOrderManagementMenu = async () => {
    const data = await generalCallApi({
      pathname: 'queues'
    });

    if (!data.error) {
      const keysOrderManagement = Object.keys(data.data);
      let optionsOrderManagement = [];
      let currentMenu = [...menuOptions];

      let isNew = false;

      keysOrderManagement.forEach(key => {
        optionsOrderManagement.push({
          title: data.data[key]?.label ?? `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
          url: `order-management/${key}`,
          enabled: true
        });
      });

      //are there queues?
      if (!!keysOrderManagement.length) {
        //Find Order management object
        let isOrderManagment = menuOptions.findIndex(option => option.title === 'Order Management');

        //Is there order management?
        if (isOrderManagment !== -1) {
          //Is there options on the order managment object
          if (!!orderManagementOptions.length) {
            //Find new queues
            keysOrderManagement.find(key => {
              const found = orderManagementOptions.find(option => option.url === key);
              if (!!found) return true;

              isNew = true;
              return false;
            })
          } else {
            isNew = true;
          };
        };

        //Find Order management object
        let isQueues = currentMenu[isOrderManagment].subMenu.findIndex(option => option.title === 'Queues');

        if (isQueues !== -1) {
          currentMenu[isOrderManagment].subMenu[isQueues] = {
            ...currentMenu[isOrderManagment].subMenu[isQueues],
            subMenu: optionsOrderManagement
          };
        };

        if (isNew) {
          setOrderManagementOptions(optionsOrderManagement);
          setMenuOptions(currentMenu);
        };
      };
    };
  };

  return (
    <nav className="header-menu flex flex-col lg:flex-row">
      {
        dataRoutes.map((option) => {
          if (option.enabled) {
            if (option.subMenu) {
              const depthLevel = 0;
              return (
                <MenuItems
                  depthLevel={depthLevel}
                  key={normalizeString(option.title)}
                  onClick={onClick}
                  option={option}
                />
              )
            } else {
              return (
                <NavLink
                  className={
                    ({ isActive }) => isDesktop
                      ? undefined
                      : isActive ? 'header-active' : undefined
                  }
                  key={normalizeString(option.title)}
                  onClick={onClick}
                  to={normalizeString(option.title)}
                >
                  {option.title}
                </NavLink>
              )
            }
          } else return ""
        })
      }
    </nav>
  )
}

export default Menu;