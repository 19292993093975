import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
//Functions
import normalizeString from "../../functions/normalizeString";
//Custom hooks
import useExternalElement from "../../hooks/useExternalElement";
import useIsMobile from '../../hooks/useIsMobile';
//Components
import Dropdown from "./Dropdown";

const MenuItems = (props) => {
  const { depthLevel, option, onClick } = props;
  //States
  const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);
  //Refs
  const refSubMenu = useRef(null);
  //Hooks
  const location = useLocation();
  //Custom hooks
  const { isDesktop } = useIsMobile();

  //Update the state that controls if the submenu is open or close
  const updateStateSubMenu = (value) => {
    const valueState = typeof value === 'boolean' ? value : !isOpenSubMenu;
    setIsOpenSubMenu(valueState)
  };

  useEffect(() => {
    const search = location.pathname.search(normalizeString(option.url ?? option.title));

    const subMenuOptions = option.subMenu.find(el => location.pathname.search(normalizeString(el.title)) !== -1);

    setIsActive(search !== -1 || !!subMenuOptions);
  }, [location, option])

  //Custom hooks
  useExternalElement(() => updateStateSubMenu(false), refSubMenu);

  return (
    <div
      className={`relative ${!isDesktop ? isOpenSubMenu ? "open-submenu'" : "px-5 py-3" : ""} ${isOpenSubMenu ? 'open-submenu' : ''} ${isOpenSubMenu && depthLevel > 0 ? "bg-gray-dark text-white" : ""} ${!isOpenSubMenu && isActive && !isDesktop ? 'header-active' : "p-0 lg:px-3 lg:py-2"} ${isActive && !isDesktop && depthLevel > 0 ? 'bg-gray-dark' : ""}`}
      ref={refSubMenu}
    >
      <button
        className={`flex items-center text-left py-3 lg:py-0 lg:pb-0 w-full justify-between ${isActive && !isDesktop ? 'header-active' : ''} ${depthLevel === 0 && !isDesktop ? "px-5" : ""} ${isOpenSubMenu ? "py-3 px-5 lg:px-0" : "py-0 px-0"} ${isActive && !isDesktop && depthLevel > 0 ? 'bg-gray-dark' : ""}`}
        key={option.title}
        onClick={updateStateSubMenu}
      >
        <span>{option.title}</span>
        <i className={`icon-${depthLevel === 0 ? "arrow-down" : "right-arrow"} ml-2 text-12`} />
      </button>
      {
        isOpenSubMenu && (
          <nav className={`${depthLevel === 0 ? "header-submenu" : "header-submenu-dropdown"}`}>
            {
              option.subMenu.map(subMenuOption => {
                const link = normalizeString(subMenuOption.url ?? subMenuOption.title);

                if (subMenuOption.enabled) {
                  if (subMenuOption.subMenu) {
                    return (
                      <Dropdown
                        isOpenSubMenu={isOpenSubMenu}
                        menu={subMenuOption}
                        onClick={() => {
                          onClick();
                          updateStateSubMenu();
                        }}
                      />
                    )
                  } else {
                    return (
                      <NavLink
                        className={({ isActive }) =>
                          isDesktop
                            ? undefined
                            : isActive ? 'header-active2' : undefined
                        }
                        onClick={() => {
                          onClick();
                          updateStateSubMenu();
                        }}
                        key={`${normalizeString(option.title)}--${normalizeString(link)}`}
                        to={`/${link}`}
                      >
                        {subMenuOption.title}
                      </NavLink>
                    )
                  };
                } else return ""
              })
            }
          </nav>
        )
      }
    </div>
  )
}

export default MenuItems;