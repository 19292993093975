import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
//Functions
import { getSearchParam } from "../../functions/getSearchParams";
//Hooks
import useIsMobile from '../../hooks/useIsMobile';

//Breadcrumb links
const BreadCrumbLink = ({ link, label, ...rest }) => {
  return (
    <div className='flex gap-3 items-center' {...rest}>
      <Link
        to={link}
      >
        <p className="max-w-xs whitespace-nowrap overflow-hidden text-ellipsis">{label}</p>
      </Link>
      <i className="icon-right-arrow text-xs" />
    </div>
  )
}

//Active section
const BreadCrumbActive = ({ label, ...rest }) => (
  <p
    className="max-w-xs whitespace-nowrap overflow-hidden text-ellipsis font-bold"
    {...rest}
  >
    {label}
  </p>
)


const BreadcrumbMenu = ({ section, menu = [] }) => {
  //Custom hooks
  const { isDesktop } = useIsMobile();
  //States
  const [maxItems, setMaxItems] = useState(isDesktop ? 4 : 2);
  //Hooks
  const navigate = useNavigate();


  useEffect(() => {
    const changeMaxItems = () => setMaxItems(isDesktop ? 4 : 2);
    changeMaxItems();
    
    window.addEventListener('resize', changeMaxItems);

    return () => window.removeEventListener('resize', changeMaxItems);
  }, [isDesktop])


  //Create links for the breadcrumbs
  const createLink = (index) => {
    let link = section;
    for (let i = 0; i <= index; i++) {
      link = `${link}/${menu[i].label}`
    }
    const param = menu[index]?.param
      ? `?${menu[index].param}=${getSearchParam(menu[index].param)}`
      : '';

    return `${link}${param}`;
  }

  //Create breadcrumb menu when there are more items than the limit
  const createMenu = () => {
    //First breadcrumb
    const newMenu = [];
    newMenu.push(<BreadCrumbLink key={`breadcrumb-${menu[0].label}`} label={menu[0].label} link={createLink(0)} />);

    const endArray = isDesktop ? -2 : -1;
    const selectOptions = menu.slice(1, endArray);

    //Othe options
    newMenu.push(
      <div className="relative" key='select'>
        <select
          className="appearance-none opacity-0 absolute top-0 left-0 w-full h-100 cursor-pointer"
          onChange={e => navigate(e.currentTarget.value)}
          defaultValue=''
        >
          <option value='' disabled hidden>value</option>
          {selectOptions.map((option, index) => <option key={option.label} value={createLink(index + 1)}>{option.label}</option>)}
        </select>
        ...
        <i className="ml-3 icon-right-arrow text-xs" />
      </div>
    );

    //Last breadcrumbs
    const lastItems = menu.slice(endArray);
    lastItems.map((item, index) => {
      const breadcrumb = index === lastItems.length - 1
        ? <BreadCrumbActive key={`breadcrumb-${item.label}`} label={item.label} />
        : <BreadCrumbLink key={`breadcrumb-${item.label}`} label={item.label} link={createLink(index)} />

      newMenu.push(breadcrumb);
      return item;
    });

    return newMenu;
  };

  return (
    <div className="items-center flex gap-3 mb-8">
      {
        menu.length <= maxItems ? (
          menu.map((breadcrumb, index) => {
            return (
              <>
                {
                  index === menu.length - 1
                    ? <BreadCrumbActive key={breadcrumb.label} label={breadcrumb.label} />
                    : <BreadCrumbLink key={breadcrumb.label} label={breadcrumb.label} link={createLink(index)} />
                }
              </>
            )
          })
        )
          : createMenu()
      }
    </div>
  )
}

export default BreadcrumbMenu;