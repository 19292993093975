const Section = (props) => {
  const { children } = props;

  return (
    <section className="mt-5 lg:mt-10">
      {children}
    </section>
  )

}

export default Section;