import { useEffect, useState } from 'react';
//Functions
import generalCallApi from '../functions/generalCallApi';

const useInitialDataOfSection = ({ pathname, properties = {}, method, dependencies = [] }) => {
  //States
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const callApi = async () => {
      setIsLoading(true);

      const response = await generalCallApi({ pathname, properties, method });

      setData(response)
      setIsLoading(false);
    }

    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies])

  return { isLoading, data }
}

export default useInitialDataOfSection;