//Components
import HomePageOrderManagement from "./HomePageOrderManagement";
import Section from "../Generals/Section";

const HomePage = () => {
  return (
    <>
      <h1><i className="icon-home mr-1" /> Home</h1>
      <Section>
        <HomePageOrderManagement />
      </Section>
    </>
  )
}

export default HomePage;