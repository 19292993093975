const getSearchParam = (param) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const product = urlParams.get(param);

  return product;
}
const getAllSearchParams = (locationSearch) => {
  const searchParams = new URLSearchParams(locationSearch);

  let obj = {}
  for (const key of searchParams.keys()) {
    obj[key] = searchParams.get(key);
  }

  return obj;
}

export { getSearchParam, getAllSearchParams };
