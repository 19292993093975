import { Children } from "react";

const DivisionComponent = (props) => {
  const { children } = props;
  const _children = Children.toArray(children);

  return (
    <>
      <div className="division">
        <div className="mb-2 lg:mb-0 lg:w-3/12">
          {_children[0]}
        </div>
        <div className="lg:w-8/12">
          {_children[1]}
        </div>
      </div>
    </>
  )
}

export default DivisionComponent;