import { useRef, useLayoutEffect, useState } from 'react';
//amCharts
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
//Hooks
import useIsMobile from '../../hooks/useIsMobile';
//Functions
import getElementFromArray from '../../functions/getElementFromArray';


const MetricsGraph = (props) => {
  const { data, description, id, isLoading, title } = props;
  //States
  const [disableSeries, setDisableSeries] = useState([]);
  //Ref
  const chartRef = useRef(null);
  //Hooks
  const { isDesktop } = useIsMobile();

  useLayoutEffect(() => {
    if (!isLoading) {
      let root = am5.Root.new(id.chart);
      // Set themes
      root.setThemes([
        am5themes_Animated.new(root)
      ]);

      root.numberFormatter.setAll({
        numberFormat: "#a",
        numericFields: ["valueY"]
      });

      // Create chart
      let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        maxTooltipDistance: 0,
        pinchZoomX: true,
        layout: root.horizontalLayout
      }));


      // Create axes
      let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: {
          timeUnit: 'minute',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {})
      }));
      xAxis.axisHeader.children.push(am5.Label.new(root, {
        text: '[bold]Number of Orders[/]',
        position: 'absolute',
        rotation: -90,
        x: -70,
        y: am5.percent(65),
      }));
      xAxis.set("tooltip", am5.Tooltip.new(root, {}));

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {})
      }));
      yAxis.set("tooltip", am5.Tooltip.new(root, {}));
      yAxis.axisHeader.children.push(am5.Label.new(root, {
        text: '[bold]Time interval[/]',
        fill: am5.color(0x000000),
        fillOpacity: 0.2,
        position: 'absolute',
        x: am5.percent(40),
        y: 450,
      }));

      // Add series
      data.forEach(metric => {
        let series = chart.series.push(am5xy.LineSeries.new(root, {
          id: metric.id,
          name: metric.label,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          legendValueText: "{valueY}",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "vertical",
            labelText: "{name}, [bold]{valueY}[/]"
          })
        }));
        series.strokes.template.setAll({
          strokeWidth: 2
        });

        series.data.setAll(metric.values);
        // Make stuff animate on load
        getElementFromArray(disableSeries, metric.id) === -1 ? series.appear() : series.hide();
      });

      // Add cursor
      chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomX"
      }));

      chart._settings.paddingBottom = 50;
      chart._settings.paddingLeft = 50;

      // Add scrollbar
      chart.set("scrollbarX", am5.Scrollbar.new(root, {
        orientation: "horizontal"
      }));
      chart.set("scrollbarY", am5.Scrollbar.new(root, {
        orientation: "vertical",
        position: 'left'
      }));

      // Add legend
      var legendRoot = am5.Root.new(id.legend);
      legendRoot.setThemes([
        am5themes_Animated.new(legendRoot),
        am5xy.DefaultTheme.new(legendRoot)
      ]);

      var legend = legendRoot.container.children.push(
        am5.Legend.new(legendRoot, {
          width: am5.percent(100),
          height: am5.percent(80),
          verticalScrollbar: am5.Scrollbar.new(legendRoot, {
            orientation: "vertical"
          })
        })
      );

      const disableSeriesId = legendEvents(legend, chart);

      // Make stuff animate on load
      chartRef.current = chart;

      return () => {
        root.dispose();
        legendRoot.dispose();
        setDisableSeries(disableSeriesId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);


  const legendEvents = (legend, chart) => {
    let disableSeriesId = [...disableSeries];

    legend.itemContainers.template.events.on("click", e => {
      let itemContainer = e.target;
      // As series list is data of a legend, dataContext is series
      let series = itemContainer.dataItem.dataContext;

      chart.series.each((chartSeries) => {
        if (chartSeries === series) {
          const settings = series._settings;
          if (settings.visible) {
            disableSeriesId.push(settings.id);
          } else {
            const position = getElementFromArray(disableSeriesId, settings.id);
            position !== -1 && disableSeriesId.splice(position, 1);
          }
        }
      })
    });

    // When legend item container is hovered, dim all the series except the hovered one
    legend.itemContainers.template.events.on("pointerover", (e) => {
      let itemContainer = e.target;

      // As series list is data of a legend, dataContext is series
      let series = itemContainer.dataItem.dataContext;

      chart.series.each((chartSeries) => {
        if (chartSeries !== series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000),
            bulletSettings: {
              fill: am5.color(0x000000)
            }
          });

        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 4,
            bulletSettings: {
              stroke: am5.color(0x00ff00)
            }
          });
        }
      })
    })

    // When legend item container is unhovered, make all series as they are
    legend.itemContainers.template.events.on("pointerout", (e) => {

      chart.series.each((chartSeries) => {

        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          strokeWidth: 2,
          stroke: chartSeries.get("fill")
        });
      });
    })

    legend.itemContainers.template.set("width", am5.p100);
    legend.valueLabels.template.setAll({
      width: am5.p100,
      textAlign: "right"
    });

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend.data.setAll(chart.series.values);

    return disableSeriesId;
  }


  return (
    <div className='mb-20'>
      <h2 className='mb-2 text-20'>{title}</h2>
      <p>{description}</p>
      <div className='flex flex-col lg:flex-row mb-5 lg:mb-0 space-y-6 lg:space-y-0'>
        <div
          className='w-full lg:w-9/12'
          id={id.chart}
          style={{
            height: 550
          }}
        />
        <div
          className='w-full lg:w-3/12'
          id={id.legend}
          style={{
            height: !!data.length ? isDesktop ? 550 : 150 : 0
          }}
        />
      </div>
    </div>
  )
}

export default MetricsGraph;