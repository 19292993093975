import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
//Store
import { useTrackedState } from '../store/store';

const useCurrentSession = () => {
  //Store
  const state = useTrackedState();
  const { isLogin, password_required } = state;
  //States
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const currentSesion = async () => {
      let isSession = false;

      try {
        await Auth.currentUserPoolUser();
        isSession = true;
      } catch (error) {
        console.error(error);
      }

      setIsAuth(isSession);
    }

    currentSesion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin, password_required])

  return { isAuth }

}

export default useCurrentSession;