import { useRef, useState } from 'react'
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
//Store
import { useDispatch, useTrackedState } from '../../store/store';

const NewPassword = () => {
	//Store
	const state = useTrackedState();
	const { user } = state;
	const dispatch = useDispatch();
	//References
	const refForm = useRef(null);
	//Hooks
	const navigate = useNavigate();

	const [hasLowercase, setLowercase] = useState(false)
	const [hasNumber, setNumber] = useState(false)
	const [hasUppercase, setUppercase] = useState(false)
	const [isShowPasswords, setShowPassword] = useState(false)
	const [arePasswordsTheSame, setPasswordsTheSame] = useState(false)


	const validateFields = () => {
		const formValues = {
			password: refForm.current[0]?.value,
			passwordConfirm: refForm.current[1]?.value,
			seePasswords: refForm.current[2]?.checked
		}
		if (formValues.password) {
			validatePassword(formValues.password)
			if (formValues.passwordConfirm) {
				setPasswordsTheSame(formValues.password === formValues.passwordConfirm)
			}
		}
		setShowPassword(formValues.seePasswords)
	}


	const putNewPassword = async (e) => {
		if (refForm.current.reportValidity()) {
			e.preventDefault();
			const data = Object.fromEntries(
				new FormData(e.target)
			);

			try {
				const response = await Auth.completeNewPassword(user, data.password);
				if (response?.authenticationFlowType === 'USER_SRP_AUTH') {
					dispatch({
						type: 'SET_DATA',
						property: 'password_required',
						value: false
					});

					navigate('/home', { replace: true });
				}

			} catch (ex) {
				console.error(ex)
			}
		}
	}


	const validatePassword = (value) => {
		setLowercase(/[a-z]/.test(value))
		setUppercase(/[A-Z ]/.test(value))
		setNumber(/\d/.test(value))
	}

	const validateSubmit = () => {
		return !(hasLowercase && hasUppercase && hasNumber && arePasswordsTheSame)
	}

	return (
		<section>
			<div className="login flex items-center">
				<form onChange={validateFields} ref={refForm} onSubmit={putNewPassword} className="w-4/5 md:w-3/5 lg:w-2/5 xl:w-4/12 mx-auto">
					<h1>
						New Password
					</h1>
					<label>Password <sup>*</sup></label>
					<input className='mb-1' name="password" type={isShowPasswords ? 'text' : 'password'} placeholder="Password" required />
					<small className={!hasUppercase ? "text-error text-left" : "text-green text-left"}>* The password must contain an uppercase</small>
					<small className={!hasNumber ? "text-error text-left" : "text-green text-left"}>* The password must contain a number</small>
					<small className={!hasLowercase ? "text-error text-left mb-4" : "text-green text-left mb-4"}>* The password must contain a lowercase</small>
					<label>Confirm your password<sup>*</sup></label>
					<input className='mb-1' name="password-confirmation" type={isShowPasswords ? 'text' : 'password'} placeholder="Confirm Password" />
					<small className={!arePasswordsTheSame ? "text-error text-left mb-4" : "text-green text-left mb-4"}>* Passwords must be the same</small>
					<label><input type="checkbox" /> See passwords</label>
					<button className="button-submit " type="submit" required disabled={validateSubmit()}>
						Send
					</button>
				</form>
			</div>
		</section>
	)
}


export default NewPassword;
