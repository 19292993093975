import Section from "./Section";

const NoExist = (props) => {
  const { children, message } = props;

  return (
    <Section>
      <div className="no-exist">
        <h1 className="text-center">
          {message}
        </h1>
        <div className="flex justify-center">
          {children}
        </div>
      </div>
    </Section>
  )
}

export default NoExist;