const handleExteriorClick = (element, onClick) => {

  // Alert if clicked on outside of element
  function handleClick(e) {
    if (!element.contains(e.target)) {
      onClick(e);
    }
  }

  // Bind the event listener
  document.addEventListener('mousedown', handleClick);

  // Unbind the event listener on clean up
  return () => document.removeEventListener('mousedown', handleClick);
}

export default handleExteriorClick;