import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Data
import dataConfiguration from "./dataConfiguration";
//Hooks
import useIsMobile from '../../hooks/useIsMobile';
//Components
import Loader from "../Generals/Loader";
import NoResultsFound from "../Generals/NoResultsFound";
import Paginator from "../Generals/Paginator";
import Search from "../Generals/Search";
import Section from "../Generals/Section";

const ConfigurationManagement = () => {
  //States
  const [allConfigurations, setAllConfigurations] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginatedData, setPaginatedData] = useState([]);

  //Custom hooks
  const { isDesktop } = useIsMobile();

  useEffect(() => {
    getConfigurations();
  }, []);

  const getConfigurations = async () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);

      const response = dataConfiguration;
      setConfigurations(response);
      setAllConfigurations(response)
    }, 1000);
  }

  const updatePaginatedData = (value) => {
    setPaginatedData(value);
  }

  return (
    <>
      <h1>Configuration Management</h1>
      <div className="flex justify-end mb-8">
        <Search
          data={allConfigurations}
          placeholder="Search here a configuration"
          property='name'
          setData={setConfigurations}
        />
      </div>
      <Section>
        {
          isLoading && <Loader />
        }
        {
          !isLoading && (
            <>
              {
                isDesktop ? (
                  <table className="table-fixed">
                    <thead>
                      <tr>
                        <th className="w-2/6">Name</th>
                        <th className="w-3/6">Description</th>
                        <th className="w-1/6">Operations</th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        paginatedData.length !== 0 ? (
                          paginatedData.map(configuration => (
                            <tr key={configuration.id}>
                              <td>{configuration.name}</td>
                              <td>{configuration.description}</td>
                              <td>
                                <Link to={`/configuration-management/${configuration.id}`}>
                                  <i className="icon-details mr-2" />
                                  Details
                                </Link>
                              </td>
                            </tr>
                          ))
                        )
                          :
                          <tr>
                            <td colSpan={3} className='text-center'>
                              No results were found
                            </td>
                          </tr>
                      }
                    </tbody>

                  </table>
                )
                  : (
                    <>
                      {
                        paginatedData.length !== 0
                          ? (
                            paginatedData.map(configuration => (
                              <div className="card-list" key={configuration.id}>
                                <div>
                                  <p>Name:</p>
                                  <p>{configuration.name}</p>
                                </div>
                                <div>
                                  <p>Description:</p>
                                  <p>{configuration.description}</p>
                                </div>
                                <Link to={`/configuration-management/${configuration.id}`}>
                                  <i className="icon-details mr-2" />
                                  Details
                                </Link>
                              </div>
                            ))
                          )
                          : <NoResultsFound />
                      }
                    </>
                  )
              }
              <Paginator
                results={configurations}
                total={configurations.length}
                updatePaginatedData={updatePaginatedData}
              />
            </>
          )
        }
      </Section>
    </>
  )
}

export default ConfigurationManagement;