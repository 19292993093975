import { useRef, useState } from 'react';
//Functions
import normalizeString from '../../functions/normalizeString';
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getAllSearchParams, getSearchParam } from '../../functions/getSearchParams';

const Search = (props) => {
  const { data, dependency = [], placeholder, property, setData } = props;
  //States
  const [query, setQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  //Ref
  const refSearchInput = useRef(null);

  const location = useLocation();
  useEffect(() => {
    const query = getSearchParam('query');
    if (!!query) {
      searchConfiguration(query);
      setQuery(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    clearInput();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependency])

  //Clear input
  const clearInput = () => {
    if (normalizeString(refSearchInput.current.value) !== "") {
      refSearchInput.current.value = "";
      searchConfiguration(undefined, true);
    }
  }

  //Search data
  const searchConfiguration = (value, clear = null) => {
    const term = clear ? '' : value;
    let query = { ...getAllSearchParams(location.search) };

    term === ''
      ? delete query.query
      : query.query = term;

    setSearchParams(query);
    setQuery(term);

    const results = data.filter(el => normalizeString(el[property].toString()).search(normalizeString(term)) !== -1);

    setData(results);
  }

  return (
    <form
      className="search-bar flex justify-end w-full lg:w-96"
      onSubmit={e => e.preventDefault()}
    >
      <input
        name='search'
        onChange={(e) => searchConfiguration(e.currentTarget.value)}
        placeholder={placeholder}
        ref={refSearchInput}
        value={query}
      />
      <button
        onClick={clearInput}
        type="button"
      >
        <i className={`text-16 icon-${refSearchInput.current?.value === "" ? "search" : "close"}`} />
      </button>
    </form>
  )
}

export default Search;