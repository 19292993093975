import { Navigate, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
//AWS
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
//Hooks
import useCurrentSession from './hooks/useCurrentSession';
//Components
import Authenticator from './components/Generals/Authenticator';
import GraphDashboard from './components/OrdersGraphs/OrdersGraphs';
import Header from './components/Header/Header';
import HomePage from './components/HomePage/HomePage';
import Login from './components/Login/Login';
import NewPassword from './components/NewPassword/NewPassword';
import NoMatch from './components/Generals/NoMatch';
import OperationsLog from './components/OperationsLog/OperationsLog';
//Order management
import OrderDetail from './components/OrderManagement/OrderDetail';
import OrdersList from './components/OrderManagement/OrdersList';
import OrderManagement from './components/OrderManagement/OrderManagement';
//Configuration management
import ConfigurationManagement from './components/ConfigurationManagement/ConfigurationManagement';
import DetailConfiguration from './components/ConfigurationManagement/DetailConfiguration';

Amplify.configure(awsconfig);
Auth.configure(awsconfig)

function App() {
  //Hooks
  const location = useLocation();
  //Custom hooks
  const { isAuth } = useCurrentSession();

  return (
    <div className="App">
      <Header />
      <section className={`container mx-auto ${location.pathname !== '/login' ? 'mt-5 lg:mt-10 mb-20 px-5' : ''}`}>
        <Authenticator>
          <Routes>
            <Route path="/"
              element={
                isAuth
                  ? <Navigate replace to='/home' />
                  : <Navigate replace to='/login' />
              }
            />
            <Route path='/orders-graphs' element={<GraphDashboard />} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/login' element={<Login />} />
            <Route path='/new-password' element={<NewPassword />} />
            <Route path='/operations-log' element={<OperationsLog />} />
            {/* Order management */}
            <Route path="/">
              <Route path='/order-management'>
                <Route path='/order-management/orders-graph' element={<HomePage />} />
                <Route path='/order-management/:section' element={<OrderManagement />} />
                <Route path='/order-management/:section/:queue' element={<OrdersList />} />
                <Route path='/order-management/:section/:queue/:order' element={<OrderDetail />} />
              </Route>
            </Route>
            {/* Configuration management */}
            <Route path="/">
              <Route path='/configuration-management' >
                <Route path='/configuration-management' element={<ConfigurationManagement />} />
                <Route path='/configuration-management/:configuration' element={<DetailConfiguration />} />
              </Route>
            </Route>
            <Route path='*' element={
              <NoMatch
                icon='error-404'
                message='Page Not Found'
              />
            } />
          </Routes>
        </Authenticator>
      </section>
    </div>
  );
}

export default App;
