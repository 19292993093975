import { Auth } from "aws-amplify";
import { useEffect, useState } from "react"

const useGroups = () => {
    const [groups, setGroups] = useState([])
    useEffect(() => {
        async function getGroups() {
            const user = await Auth.currentAuthenticatedUser();
            const userGroups = user?.signInUserSession?.accessToken?.payload["cognito:groups"]
            setGroups(userGroups || [])
        }
        getGroups()
    }, [])

    return { groups };
}

export default useGroups