import React from 'react';
import NoMatch from './NoMatch';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        hasError: false
      })
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <NoMatch
          icon='warning'
          message='Something went wrong'
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;