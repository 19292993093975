import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
//Store
import { useDispatch, useTrackedState } from '../../store/store';
import ErrorBoundary from './ErrorBoundary';

const Authenticator = (props) => {
  const { children } = props;
  //Store
  const state = useTrackedState();
  const { password_required } = state;
  const dispatch = useDispatch();
  //Hooks
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentSesion = async () => {
      let url = location.pathname;

      try {
        const response = await Auth.currentUserPoolUser();
        dispatch({
          type: 'SIGN_IN',
          value: {
            profile: response.attributes
          }
        });

        url = url === '/login' || url === '/new-password'
          ? {
            pathname: '/home',
            search: location.search
          }
          : location;

      } catch (error) {
        url = password_required
          ? '/new-password'
          : '/login';
      }

      navigate(url, { replace: true })
    }

    currentSesion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <ErrorBoundary
        location={location.pathname}
      >
        {children}
      </ErrorBoundary>
    </>
  )
}

export default Authenticator