import { useEffect } from 'react';

const NoPendingOrders = (props) => {
  const { message, onClose } = props;

  useEffect(() => {
    window.addEventListener("keydown", closeModalKey);
    return () => {
      window.removeEventListener('keydown', closeModalKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModalKey = (e) => {
    if (e.which === 27) {
      onClose();
    }
  }

  return (
    <section className="popup">
      <div className="popup-content">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-full">
          <div className="popup-message"
          >
            {message}
          </div>
        </div>

        <button
          className="button-close"
          onClick={onClose}
          type="button"
        >
          <i className="icon-close" />
        </button>

        <div className="flex justify-center mt-8">
          <button
            className="py-3 rounded-lg px-20 bg-gray-darker text-white"
            onClick={onClose}
            type="button"
          >
            Ok
          </button>
        </div>
      </div>
      <div className="black-canvas" onClick={onClose} />
    </section>
  )
}

export default NoPendingOrders;