import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
//Code editor
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
//Functions
import generalCallApi from '../../functions/generalCallApi';
import { getSearchParam } from "../../functions/getSearchParams";
//Components
import BreadcrumbMenu from "../Generals/BreadcrumbMenu";
import DivisionComponent from "../Generals/DivisionComponent";
import Loader from "../Generals/Loader";
import NoExist from "../Generals/NoExist";
import Section from "../Generals/Section";
import PageHeader from "../Generals/PageHeader";

const OrderDetail = () => {
  //States
  const [infoOrder, setInfoOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');

  //Hooks
  const params = useParams();
  const { section, queue, order } = params;

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, queue, order])


  //Get order information
  const getOrder = async () => {
    const connection = getSearchParam('connection');
    const orderId = getSearchParam('order-id');
    const data = await generalCallApi({
      pathname: `order/${connection}/${queue}/${order}`
    });

    if (!data.error) {
      const dataOrder = {
        queue: {
          id: queue,
          name: queue
        },
        order: {
          ...data.data.headers,
          message: data.data.message,
          orderId: orderId
        },
      }

      setInfoOrder(dataOrder);
    } else {
      setMessage(data.message);
    }

    setIsLoading(false);
  }

  return (
    <>

      <BreadcrumbMenu
        menu={[
          { label: section },
          { label: queue, param: 'connection' },
          { label: order }
        ]}
        section='/order-management'
      />
      <PageHeader>
        <div>
          <h1 className="mb-0">Order detail</h1>
          <h2>
            <span className="font-semibold">Queue name: </span> {infoOrder?.queue?.name} <br />
            <span className="font-semibold">Message id: </span> {infoOrder?.order['message-id']}
          </h2>
        </div>
      </PageHeader>
      {
        isLoading
          ? (
            <div className="mt-8">
              <Loader />
            </div>
          )
          : (
            <>
              {
                !!infoOrder ? (
                  <>
                    <Section>
                      <DivisionComponent>
                        <h3>MBID</h3>
                        <label>
                          <input className="info-order" value={infoOrder.order.mbid ?? '-'} disabled />
                          <i className="icon-lock" />
                        </label>
                      </DivisionComponent>
                      <DivisionComponent>
                        <h3>Order ID</h3>
                        <label>
                          <input className="info-order" value={infoOrder.order.orderId} disabled />
                          <i className="icon-lock" />
                        </label>
                      </DivisionComponent>
                      <DivisionComponent>
                        <h3>Message details</h3>
                        <>
                          <AceEditor
                            placeholder="Message details"
                            mode="xml"
                            theme="github"
                            name="message-detail"
                            fontSize={14}
                            showPrintMargin={false}
                            showGutter={true}
                            value={infoOrder.order.message}
                            readOnly={true}
                            setOptions={{
                              enableBasicAutocompletion: true,
                              enableLiveAutocompletion: true,
                              enableSnippets: true,
                              showLineNumbers: true,
                              tabSize: 2,
                            }}
                            style={{
                              color: '#a5a5a7',
                              backgroundColor: '#f4f4f6',
                              width: '100%',
                            }}
                            wrapEnabled={true}
                          />
                        </>
                        <>
                        </>
                      </DivisionComponent>
                    </Section>
                  </>
                )
                  : (
                    <NoExist
                      message={message}
                    >
                      <Link
                        className="button-dark"
                        to={`/order-management/${section}/${queue}?connection=${getSearchParam('connection')}`}
                      >
                        Go back
                      </Link>
                    </NoExist>
                  )
              }
            </>
          )
      }
    </>
  )
}

export default OrderDetail;