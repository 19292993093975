import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//Functions
import generalCallApi from '../../functions/generalCallApi';
//Components
import Loader from '../Generals/Loader';

const HomePageOrderManagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orderManagementOptions, setOrderManagementOptions] = useState([]);

  useEffect(() => {
    getOrders();
    const interval = setInterval(() => {
      getOrders();
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get queue lists
  const getOrders = async () => {
    const data = await generalCallApi({
      pathname: 'queues'
    });

    if (!data.error) {
      const options = Object.keys(data.data).map(key => {
        return (
          {
            link: key,
            name: data.data[key]?.label ?? `${key.charAt(0).toUpperCase()}${key.slice(1)}`
          }
        )
      });
      setOrderManagementOptions(options);
    }

    setIsLoading(false);
  }

  return (
    <>
      {
        isLoading && <Loader />
      }
      {
        !isLoading && (
          <div className='home-order-management flex flex-wrap w-full gap-10 xl:gap-20 mt-8 xl:w-10/12 mx-auto justify-center'>
            {
              orderManagementOptions.map(list => (
                <Link
                  className='w-full md:w-5/12 lg:w-1/4 text-20 text-center font-semibold rounded-xl py-14 lg:py-20 px-10 border border-gray-lighter shadow-md hover:bg-gray hover:text-white transition-all duration-200'
                  to={`/order-management/${list.link}`}
                  key={list.name}
                >
                  {list.name} <br /> Orders Queues
                </Link>
              ))
            }
          </div>
        )
      }
    </>
  )
}

export default HomePageOrderManagement;