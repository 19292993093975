import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "../../store/store";
import { Auth } from 'aws-amplify';
import { ToastContainer, toast } from "react-toastify";
const Login = () => {

  const dispatch = useDispatch();
  //Ref
  const refForm = useRef(null);
  //Hooks
  const navigate = useNavigate();


  //Sign in
  const signIn = async (e) => {
    if (refForm.current.reportValidity()) {
      e.preventDefault();
      const data = Object.fromEntries(
        new FormData(e.target)
      )
      try {
        const user = await Auth.signIn(data.email, data.password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch({
            type: 'SIGN_IN',
            value: {
              password_required: true,
              profile: {
                email: data.email,
              },
              user: user
            }
          });

          navigate('/new-password');
        } else if (user?.authenticationFlowType === 'USER_SRP_AUTH') {
          dispatch({
            type: 'SIGN_IN',
            value: {
              password_required: false,
              profile: user.attributes,
              user: user
            }
          });

          navigate('/home', { replace: true })
        }
      } catch (ex) {
        clearForm();
        toast.error(`Verify your credentials`, JSON.parse(process.env.REACT_APP_TOASTIFY));
      }
    }

  }

  const clearForm = () => {
    for (const field of refForm.current) {
      if (field?.value) field.value = ''
    }
  }

  return (
    <section>
      <div className="login flex justify-center items-center">
        <form ref={refForm} onSubmit={signIn} className="basis-3/12">
          <span className="logo">
            <i className="icon-logo text-80" />
          </span>
          <h1>
            Sign in
          </h1>
          <label>Email <sup>*</sup></label>
          <input name='email' type='email' placeholder="email@service.com" required />
          <label>Password <sup>*</sup></label>
          <input name='password' type='password' placeholder="Password" />
          <button className="button-submit " type="submit" required>
            Sign in
          </button>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  )
}

export default Login;