import { useReducer } from "react";
import { createContainer } from "react-tracked";
import reducer from "./actions";

const initialState = {
  isLogin: false,
  password_required: false,
  profile: {}
}

const useValue = () => useReducer(reducer, initialState);

export const {
  Provider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
