import { Children } from "react";

const PageHeader=({children, title})=>{
  const _children = Children.toArray(children);

  return (
    <div className="flex flex-col lg:flex-row lg:items-end gap-1 justify-between">
      {_children[0]}

      {_children[1]}
    </div>
  )
}

export default PageHeader;