import { useEffect } from "react";

const useExternalElement = (callback, ref) => {
  useEffect(() => {
    // Alert if clicked on outside of element
    function handleClickOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        callback(e.target)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default useExternalElement;
