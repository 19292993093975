//Components
import MenuItems from "./MenuItems";

const depthLevel = 1;
const Dropdown = ({ menu }) => {
  return (
    <MenuItems
      depthLevel={depthLevel}
      option={menu}
    />
  )
}

export default Dropdown;