import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//Functions
import formatDate from "../../functions/formatDate";
import generalCallApi from '../../functions/generalCallApi';
//Hooks
import useInitialDataOfSection from '../../hooks/useInitialDataOfSection';
import useIsMobile from '../../hooks/useIsMobile';
//Components
import Loader from "../Generals/Loader";
import NoPendingOrders from "../Modals/OrderManagement/NoPendingOrders";
import NoResultsFound from "../Generals/NoResultsFound";
import Paginator from "../Generals/Paginator";
import Search from "../Generals/Search";
import Section from "../Generals/Section";
import PageHeader from "../Generals/PageHeader";

const OrderManagement = () => {
  //States
  const [allLists, setAllLists] = useState([]);
  const [isNoOrder, setIsNoOrder] = useState(null);
  const [lastUpdate, setLastUpdate] = useState('');
  const [lists, setLists] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [title, setTitle] = useState('');
  //Hooks
  const params = useParams();
  const { section } = params;
  const navigate = useNavigate();
  //Custom hooks
  const { isDesktop } = useIsMobile();
  const { isLoading: isLoadingQueue, data: response } = useInitialDataOfSection({
    pathname: 'queues',
    dependencies: [section]
  });


  useEffect(() => {
    const interval = setInterval(() => {
      getOrders();
    }, 30000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);


  useEffect(() => {
    !isLoadingQueue && updateState(response);
    if (!!response && !response?.error) {
      setTitle(`${response.data[section].label} Orders`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingQueue, response])


  //Update paginated data
  const updatePaginatedData = (value) => {
    setPaginatedData(value);
  }

  //Get queue lists
  const getOrders = async () => {
    const data = await generalCallApi({
      pathname: 'queues'
    });

    updateState(data);

  }

  const updateState = (response) => {
    if (!response.error) {
      setLastUpdate(formatDate(new Date()));
      setLists(response.data[section].queues);
      setAllLists(response.data[section].queues);
    }
  }

  //Generate details link
  const detailsLink = (order) => {
    return `/order-management/${section}/${order.destinationName}?connection=${order.connection}`
  }

  //Check if the queue has orders
  const isOrdersInQueue = (order) => {
    order.pendingMessages !== 0
      ? navigate(detailsLink(order))
      : setIsNoOrder(order);
  }

  //Close modal
  const closeModal = () => {
    setIsNoOrder(null);
  }

  return (
    <>
      <PageHeader>
        <h1>{title}</h1>
        <div className="flex flex-col lg:flex-row lg:justify-end lg:items-center gap-y-4 lg:gap-x-10 lg:w-6/12">
          <div>
            <p>
              <span className="font-semibold">Last updated: </span> {lastUpdate}
            </p>
          </div>

          <Search
            data={allLists}
            dependency={[section, isLoadingQueue]}
            placeholder="Search here a queue list"
            property='destinationName'
            setData={setLists}
          />
        </div>
      </PageHeader>

      <Section>


        {
          isLoadingQueue && <Loader />
        }
        {
          !isLoadingQueue && (
            <>
              {
                isDesktop ? (
                  <table className="table-fixed">
                    <thead>
                      <tr>
                        <th>Queue name</th>
                        <th className="w-1/6">Number of pending orders</th>
                        <th className="w-1/6">Operations</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        paginatedData.length !== 0 ? (
                          paginatedData.map((order) => (
                            <tr
                              className='cursor-pointer'
                              key={order.destinationName}
                              onClick={() => isOrdersInQueue(order)}
                            >
                              <td>{order.destinationName}</td>
                              <td>{order.pendingMessages}</td>
                              <td>
                                <button type="button">
                                  <i className="icon-details mr-2" />
                                  Details
                                </button>
                              </td>
                            </tr>
                          ))
                        )
                          : (
                            <tr>
                              <td colSpan={3} className='text-center'>
                                No results were found
                              </td>
                            </tr>
                          )
                      }
                    </tbody>
                  </table>
                ) : (
                  <>
                    {
                      paginatedData.length !== 0
                        ? (
                          paginatedData.map(order => (
                            <div className="card-list" key={order.destinationName}>
                              <div>
                                <p>Queue name:</p>
                                <p>{order.destinationName}</p>
                              </div>
                              <div>
                                <p>Number of pending orders:</p>
                                <p>{order.pendingMessages}</p>
                              </div>
                              <button onClick={() => isOrdersInQueue(order)}>
                                <i className="icon-details mr-2" />
                                Details
                              </button>
                            </div>
                          ))
                        )
                        : <NoResultsFound />
                    }
                  </>
                )
              }
              <Paginator
                results={lists}
                total={lists.length}
                updatePaginatedData={updatePaginatedData}
              />
            </>
          )
        }
      </Section>
      {
        !!isNoOrder && (
          <NoPendingOrders
            message='There are no pending orders in this queue.'
            onClose={closeModal}
          />
        )
      }
    </>
  )
}

export default OrderManagement;