const abbreviateTotal = (total) => {
  if (Math.abs(total) >= 1.0e+9) {
    return (Math.round((total / 1.0e+9) * 10) / 10).toLocaleString() + 'B';
  }
  if (Math.abs(total) >= 1.0e+6) {
    return (Math.round((total / 1.0e+6) * 10) / 10).toLocaleString() + 'M';
  }
  if (Math.abs(total) >= 1.0e+3) {
    return (Math.round((total / 1.0e+3) * 10) / 10).toLocaleString() + 'K';
  }
  return total.toLocaleString();
}

export default abbreviateTotal;