import { Children, useEffect, useRef, useState } from "react";
//Functions
import handleExteriorClick from '../../functions/handleExteriorClick';

const Modal = ({ children, closeModal, className }) => {
  return (
    <div className={`absolute border border-gray-lightest py-4 px-6 left-0 top-full z-50 bg-white shadow-2xl w-60 rounded-xl`}>
      {children}
    </div>
  )
}

const ButtonWithModal = (props) => {
  const { children } = props;
  const _children = Children.toArray(children);

  //States
  const [isOpenModal, setIsOpenModal] = useState(false);
  //Ref
  const refModal = useRef(null);

  useEffect(() => {
    if (!isOpenModal) return;
    const unbind = handleExteriorClick(refModal.current, () => updateStateModal(false));
    return unbind;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal]);


  //Update state modal (open, close)
  const updateStateModal = (value) => {
    const valueStateModal = typeof value === 'boolean' ? value : !isOpenModal;
    setIsOpenModal(valueStateModal);
  }

  return (
    <div
      className="relative z-50 d-flex justify-center items-center"
      ref={refModal}
    >
      <button onClick={updateStateModal}>
        {_children[0]}
      </button>
      {isOpenModal && (
        <Modal>
          {_children[1]}
        </Modal>
      )}
    </div>
  )

}

export default ButtonWithModal;