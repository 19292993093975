const dataRoutes = [
  {
    title: 'Order Management',
    enabled: true,
    subMenu: [
      {
        title: 'Queues',
        url: '/order-management',
        enabled: true,
        subMenu: []
      },
      {
        title: 'Orders Graphs',
        enabled: true,
      },
      {
        title: 'Operations log',
        enabled: true,
      }
    ]
  },
  {
    title: 'Configuration Management',
    enabled: false
  },
  {
    title: 'Batch Operations',
    enabled: false
  }
];

export default dataRoutes;