const ConfirmDelete = (props) => {
  const { cancelText, children, confirmMessage, confirmText, isEliminating, onCancel, onEliminate } = props;
  return (
    <section className="popup">
      <div className="popup-content">
        <div className="flex flex-col lg:flex-row w-10/12 lg:w-full">
          {children}
          <div className="popup-message"
          >
            {confirmMessage}
          </div>
        </div>

        <button
          className="button-close"
          onClick={onCancel}
          type="button"
        >
          <i className="icon-close" />
        </button>

        <div className="action-buttons-2">
          <button
            className="button-cancel"
            onClick={onCancel}
            type="button"
          >
            {cancelText}
          </button>
          <button
            className="button-delete-2 flex justify-center items-center"
            onClick={onEliminate}
            type="button"
          >
            {
              isEliminating
                ? <i className="loader icon-refresh" />
                : confirmText}
          </button>
        </div>
      </div>
      <div className="black-canvas" onClick={onCancel} />
    </section>
  )
}

export default ConfirmDelete;