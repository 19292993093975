import { useEffect, useState } from "react";
//Hooks
import useIsMobile from '../../hooks/useIsMobile';
//Functions
import abbreviateTotal from '../../functions/abbreviateTotal';

//Environment Variables
const paginatorData = {
  mobile: JSON.parse(process.env.REACT_APP_PAGINATOR_RESPONSIVE),
  desktop: JSON.parse(process.env.REACT_APP_PAGINATOR)
}

const Paginator = ({ results, total, updatePaginatedData } ) => {
  //Hooks
  const { isDesktop } = useIsMobile();

  //Component states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(!isDesktop ? paginatorData.mobile.elements : paginatorData.desktop.elements)
  //Amount of number in the paginator
  const [baseNumber, setBaseNumber] = useState(null);
  //Maximum number
  const [maximumNumber, setMaximumNumber] = useState(null);
  //Minimum number
  const [minimumNumber, setMinimumNumber] = useState(1);
  //Amount of pages that should be showed depend on the number of pages
  const [pages, setPages] = useState([]);


  useEffect(() => {
    //Amount of elements depending on the responsive
    const itemsPerPage = !isDesktop ? paginatorData.mobile.elements : paginatorData.desktop.elements;
    setItemsPerPage(itemsPerPage);

    //Amount of items that will be showed in the paginator
    const maximumLimit = !isDesktop ? paginatorData.mobile.paginator : paginatorData.desktop.paginator;
    setMaximumNumber(maximumLimit);

    //Amount of number that will be showed in the paginator. It's going to be 2 or 5 based on the responsive
    setBaseNumber(maximumLimit);

    setMinimumNumber(1);
    setCurrentPage(1);

  }, [isDesktop, total]);


  useEffect(() => {
    //Generate the total pages depending on the number of results
    let amountPages = [];
    for (let i = 1; i <= Math.ceil(total / itemsPerPage); i++) {
      amountPages.push(i);
    }
    setPages(amountPages);
    setCurrentPage(1);
  }, [itemsPerPage, total])


  useEffect(() => {
    const end = currentPage * itemsPerPage;
    const beginning = end - itemsPerPage;
    const data = results.slice(beginning, end);

    window.scrollTo(0, 0);

    updatePaginatedData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, results])



  //Go to the first results
  const firstPage = () => {
    setCurrentPage(1);
    setMaximumNumber(!isDesktop ? paginatorData.mobile.paginator : paginatorData.desktop.paginator);
    setMinimumNumber(1);
  }

  //Go to the last results
  const lastPage = () => {
    setCurrentPage(pages.length);

    const amountItems = pages.length % baseNumber !== 0
      ? (pages.length % baseNumber) - 1
      : baseNumber - 1;

    setMinimumNumber(pages.length - amountItems);
    setMaximumNumber(pages.length);
  }


  //Go to the previous page
  const previousPage = () => {
    const newPage = currentPage - 1;
    if (newPage < minimumNumber) {
      setMinimumNumber(minimumNumber - baseNumber);
      setMaximumNumber(minimumNumber - 1);
    }
    setCurrentPage(newPage);
  }

  //Go to the next page
  const nextPage = () => {
    const newPage = currentPage + 1;
    if (newPage > maximumNumber) {
      setMaximumNumber(maximumNumber + baseNumber);
      setMinimumNumber(minimumNumber + baseNumber);
    }
    setCurrentPage(newPage)
  }


  //Render buttons paginator
  const itemsPaginator = () => {
    let itemPage = [];
    pages.forEach((number) => {
      if (number <= maximumNumber && number >= minimumNumber) {
        itemPage.push(
          <button
            className={`${currentPage === number ? 'active-page' : ''}`}
            key={number}
            onClick={() => setCurrentPage(number)}
            type='button'
          >
            <p>{number}</p>
          </button>
        )
      }
    })

    return itemPage;
  }

  const changeItemsPerPage = (e) => {
    setItemsPerPage(e.currentTarget.value);
  }

  return (
    <div className="flex justify-between items-center mt-8">
      {
        total > 0 && (
          <>
            <div>
              Rows per page
              <select className="ml-2 px-2 py-1 rounded-lg border border-gray-light" onChange={changeItemsPerPage} value={itemsPerPage}>
                {
                  process.env.REACT_APP_ITEMS_TABLE.split(", ").map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))
                }
              </select>
            </div>

            <div className="flex justify-end items-center w-100 gap-8">
              <div>
                Showing {" "}
                {abbreviateTotal(currentPage === 1 ? 1 : ((itemsPerPage * (currentPage - 1))) + 1)}
                -
                {abbreviateTotal(currentPage * itemsPerPage > total ? total : itemsPerPage * currentPage)}
                {" "} of {abbreviateTotal(total)}
              </div>
              {
                total > itemsPerPage && (
                  <div className="action-buttons paginator mt-0">
                    <button
                      className="arrow-buttons pr-1"
                      disabled={currentPage === pages[0]}
                      onClick={previousPage}
                      type="button"
                    >
                      <i className="icon-left-arrow" />
                    </button>
                    {
                      minimumNumber > 1 && (
                        <>
                          <button onClick={firstPage} type="button">
                            <p>1</p>
                          </button>
                          <i className="icon-dots mr-0" />
                        </>
                      )
                    }
                    {itemsPaginator()}
                    {
                      pages.length > maximumNumber && (
                        <>
                          <i className="icon-dots mr-0" />
                          <button type="button" onClick={lastPage}>
                            <p>{pages.length}</p>
                          </button>
                        </>
                      )
                    }
                    <button
                      className="arrow-buttons pl-1"
                      disabled={currentPage === pages[pages.length - 1]}
                      onClick={nextPage}
                      type="button"
                    >
                      <i className="icon-right-arrow" />
                    </button>
                  </div>
                )
              }
            </div>
          </>
        )
      }
    </div>
  )

}

export default Paginator;