import { useState, useEffect } from 'react';
//Hooks
import useInitialDataOfSection from '../../hooks/useInitialDataOfSection';
import useIsMobile from '../../hooks/useIsMobile';
//Components
import Loader from '../Generals/Loader';
import NoResultsFound from '../Generals/NoResultsFound';
import PageHeader from '../Generals/PageHeader';
import Paginator from '../Generals/Paginator';
import Search from '../Generals/Search';
import Section from "../Generals/Section";

const OperationsLog = () => {
  //States
  const [allLogs, setAllLogs] = useState([]);
  const [lists, setLists] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  //Custom hooks
  const { isDesktop } = useIsMobile();
  const { isLoading, data } = useInitialDataOfSection({ pathname: 'logs/delete', method: 'GET' })


  useEffect(() => {
    if (!!data) {
      if (!data.error) {
        setAllLogs(data.data);
        setPaginatedData(data.data);
        setLists(data.data);
      };
    };
  }, [data]);


  //Update paginated data
  const updatePaginatedData = (value) => {
    setPaginatedData(value);
  };


  return (
    <>
      <Section>
        <PageHeader>
          <h1>Operations Log</h1>
          <div className="flex flex-col lg:flex-row lg:justify-end lg:items-end gap-y-4 lg:gap-0">
            <Search
              data={allLogs}
              dependency={[isLoading]}
              placeholder="Search here a log"
              property='email'
              setData={setLists}
            />
          </div>
        </PageHeader>
        {
          isLoading
            ? <Loader />
            : (
              <Section>
                <>
                  {
                    isDesktop
                      ? (
                        <table className="table-fixed">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Order ID</th>
                              <th className="w-4/12">Message ID</th>
                              <th>Timestamp</th>
                              <th className="w-3/12">User</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              paginatedData.length !== 0
                                ? (
                                  paginatedData.map((log) => (
                                    <tr key={log.messageId}>
                                      <td>{log.action}</td>
                                      <td>{log.orderId}</td>
                                      <td>{log.messageId}</td>
                                      <td>{log.timestamp}</td>
                                      <td>{log.email}</td>
                                    </tr>
                                  ))
                                )
                                : (
                                  <tr>
                                    <td colSpan={5} className='text-center'>
                                      No results were found
                                    </td>
                                  </tr>
                                )
                            }
                          </tbody>
                        </table>
                      )
                      : (
                        <>
                          {
                            paginatedData.length !== 0
                              ? (
                                paginatedData.map(log => (
                                  <div className='card-list' key={log.messageId}>
                                    <div>
                                      <p>Action:</p>
                                      <p>{log.action}</p>
                                    </div>
                                    <div>
                                      <p>Order ID:</p>
                                      <p>{log.orderId}</p>
                                    </div>
                                    <div>
                                      <p>Message ID:</p>
                                      <p>{log.messageId}</p>
                                    </div>
                                    <div>
                                      <p>Timestamp:</p>
                                      <p>{log.timestamp}</p>
                                    </div>
                                    <div>
                                      <p>User:</p>
                                      <p>{log.email}</p>
                                    </div>
                                  </div>
                                ))
                              )
                              : <NoResultsFound />
                          }
                        </>
                      )
                  }
                  <Paginator
                    results={lists}
                    total={lists.length}
                    updatePaginatedData={updatePaginatedData}
                  />
                </>
              </Section>
            )
        }
      </Section>
    </>
  )
}

export default OperationsLog;