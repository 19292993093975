import { useEffect, useState } from 'react';
//Functions
import generalCallApi from '../../functions/generalCallApi';
//Components
import MetricsGraph from './MetricsGraph';
import Section from "../Generals/Section";
import ButtonWithModal from '../Generals/ButtonWithModal';
import useInitialDataOfSection from '../../hooks/useInitialDataOfSection';

const options = [
  { label: "1h", value: 1 },
  { label: "3h", value: 2 },
  { label: "12h", value: 3 },
  { label: "1d", value: 4 },
  { label: "3d", value: 5 },
  { label: "1w", value: 6 },
]

const OrdersGraphs = () => {
  const [activePeriodOfTime, setActivePeriodOfTime] = useState(1);
  const [allMetrics, setAllMetrics] = useState({ sum: {}, minute: {} });
  const [filterOptions, setFilterOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState({ sum: [], minute: [] });

  //Custom hooks
  const { data: response } = useInitialDataOfSection({
    pathname: 'queues'
  })

  useEffect(() => {
    getMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePeriodOfTime]);


  useEffect(() => {
    if (!!response) {
      if (!response.error) {
        const filters = Object.keys(response.data).map((key, index) => {
          return {
            field: key,
            label: response.data[key].label,
            value: true,
            series: (index + 1) * 3
          }
        });

        setFilterOptions(filters);
      }
    }
  }, [response])

  //Get metrics
  const getMetrics = async () => {
    setMetrics({ sum: {}, minute: {} });
    setIsLoading(true);

    const pathname = `${process.env.NODE_ENV !== 'production' ? 'stage-metrics' : 'metrics'}/${activePeriodOfTime}`
    const data = await generalCallApi({pathname});

    if (!data.error) {
      let series = { minute: [], sum: [] };
      let seriesObject = { minute: {}, sum: {} };
      for (const key in data.data) {
        const element = data.data[key];
        seriesObject.sum[key] = objectToArray(element.sum)
        seriesObject.minute[key] = objectToArray(element.byMinutes)
      };

      Object.keys(seriesObject.sum).forEach(queue => {
        series.sum = [...series.sum, ...seriesObject.sum[queue]];
        series.minute = [...series.minute, ...seriesObject.minute[queue]];
      });

      setAllMetrics(seriesObject);
      !!filterOptions.length ? getSeriesBasedOnFilters(filterOptions, seriesObject) : setMetrics(series);
    }
    setIsLoading(false);
  }


  const objectToArray = (object) => {
    let array = [];
    for (const key in object) {
      const values = object[key].Values.map((value, index) => {
        return {
          date: new Date(object[key].Timestamps[index]).getTime(),
          value: value
        };
      })

      array.push({
        id: object[key].Id,
        label: key,
        values: values.reverse(),
      })
    }
    return array;
  }

  //Chnage period of time
  const changePeriodOfTime = (value) => {
    setActivePeriodOfTime(value);
  }

  //Update filters
  const updateFilters = (e, index) => {
    let previousFilters = [...filterOptions];
    previousFilters[index] = { ...previousFilters[index], value: e.currentTarget.checked };

    getSeriesBasedOnFilters(previousFilters, allMetrics);
    setFilterOptions(previousFilters);
  }

  const getSeriesBasedOnFilters = (filters, metrics) => {
    setMetrics({ sum: [], minute: [] });
    let data = { sum: [], minute: [] };
    filters.forEach(filter => {
      if (filter.value) {
        data = {
          sum: [...data.sum, ...metrics.sum[filter.field]],
          minute: [...data.minute, ...metrics.minute[filter.field]]
        }
      };
    });

    setMetrics(data);
  };

  return (
    <>
      <h1>Orders Graphs</h1>

      <Section>
        <div className="sm:flex justify-between items-center pb-5 sm:pb-8">
          <ButtonWithModal>
            <p className='font-semibold text-18'>
              <i className='icon-filter' /> Filters
            </p>
            <div className='last:pb-0'>
              {
                filterOptions.map((filter, index) => {
                  return (
                    <div className='pb-4 last:pb-0' key={filter.field}>
                      <label>
                        <input
                          className="mr-1 mb-0"
                          onChange={(e) => { updateFilters(e, index) }}
                          type="checkbox"
                          checked={filter.value}
                        />
                        {filter.label}
                      </label>
                    </div>
                  )
                })
              }
            </div>
          </ButtonWithModal>
          <div className="flex sm:justify-end mt-4 sm:mt-0">
            {
              options.map(option => (
                <button
                  className={`
                  px-2 py-1 odd:border even:border-y last:border-r border-gray-light 
                  ${activePeriodOfTime === option.value
                      ? "bg-gray-dark text-white"
                      : "hover:bg-gray-lighter"}
                `}
                  key={option.label}
                  onClick={() => changePeriodOfTime(option.value)}
                  type="button"
                >
                  {option.label}
                </button>
              ))
            }
          </div>
        </div>

        <MetricsGraph
          data={metrics?.minute}
          description="Orders made every 5 minutes in the different Material Technologies' properties."
          id={{
            chart: 'chartdiv-minute',
            legend: 'legenddiv-minute',
          }}
          isLoading={isLoading}
          title="Orders Per Minute"
        />

        <MetricsGraph
          data={metrics?.sum}
          description="Summatory of the total of orders from the different Material Technologies' properties."
          id={{
            chart: 'chartdiv-sum',
            legend: 'legenddiv-sum',
          }}
          isLoading={isLoading}
          title="Orders Sum"
        />
      </Section>
    </>
  )
}

export default OrdersGraphs;