//Format dates format dd/mm/yyyy
const formatDate = (date) => {
  if (!!date) {
    const newDate = new Date(date);
    const isValid = newDate instanceof Date && !isNaN(newDate);

    let formatDate = '--/--/----';

    if (isValid) {
      formatDate = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format(newDate);
    };

    return formatDate;
  }
}

export default formatDate;