import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-xml";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
//Data
import dataConfiguration from "./dataConfiguration";
//Components
import DivisionComponent from "../Generals/DivisionComponent";
import Loader from "../Generals/Loader";
import NoExist from "../Generals/NoExist";
import Section from "../Generals/Section";

const objectConfiguration = `{
  "_id": "6306b4b44de620ebd6c9e922",
  "index": 0,
  "guid": "5a1bd092-8105-4956-a70d-9829ccec1c3d",
  "isActive": true,
  "balance": "$3,651.22",
  "picture": "http://placehold.it/32x32",
  "age": 30,
  "eyeColor": "green",
  "name": "Vicky Holden",
  "gender": "female",
  "company": "PEARLESEX",
  "email": "vickyholden@pearlesex.com",
  "phone": "+1 (809) 460-3701",
  "address": "885 Lincoln Road, Wolcott, Hawaii, 8823",
  "about": "Adipisicing fugiat nulla Lorem veniam Lorem aute eiusmod excepteur commodo duis eiusmod labore aliqua. Ea consequat consequat cillum Lorem duis est sunt minim id ipsum est. Nostrud id dolore quis aliqua eiusmod fugiat nostrud. Pariatur amet aliqua fugiat Lorem ex cillum sunt minim id veniam Lorem laboris. Est proident laboris elit duis eiusmod. Ex velit tempor sunt minim aliquip commodo ea officia. Nisi est mollit consequat incididunt enim do excepteur non.\r\n",
  "registered": "2020-09-07T08:53:34 +05:00",
  "latitude": -35.232332,
  "longitude": 106.248962,
  "tags": [
    "aliquip",
    "commodo",
    "nisi",
    "fugiat",
    "anim",
    "commodo",
    "eu"
  ],
  "friends": [
    {
      "id": 0,
      "name": "Alicia Cooley"
    },
    {
      "id": 1,
      "name": "Alston Allen"
    },
    {
      "id": 2,
      "name": "Walker Reeves"
    }
  ],
  "greeting": "Hello, Vicky Holden! You have 2 unread messages.",
  "favoriteFruit": "banana"
}`

const DetailConfiguration = () => {
  //States
  const [infoConfiguration, setInfoConfiguration] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [messageDetail, setMessageDetail] = useState(objectConfiguration);

  //Hooks
  const params = useParams();
  const { configuration } = params;

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration])

  const getOrder = async () => {
    const currentConfiguration = !!dataConfiguration
      ? dataConfiguration.find(list => list.id === configuration)
      : null;

    setTimeout(() => {
      setIsLoading(false);
      setInfoConfiguration(currentConfiguration);
    }, 1000);
  }

  const onChangeMessageDetail = (value) => {
    setMessageDetail(value);
  }

  return (
    <>
      {
        isLoading
          ? (
            <div className="mt-8">
              <Loader />
            </div>
          )
          : (
            <>
              {
                !!infoConfiguration ? (
                  <>
                    <h1>Configuration detail - {infoConfiguration.name}</h1>
                    <hr/>
                    <h2>
                      {infoConfiguration.id}
                    </h2>
                    <Section>
                      <DivisionComponent>
                        <h3>Name</h3>
                        <>
                          <p className="info-order">{infoConfiguration.name}</p>
                        </>
                      </DivisionComponent>
                      <DivisionComponent>
                        <h3>ID</h3>
                        <>
                          <p className="info-order">{infoConfiguration.id}</p>
                        </>
                      </DivisionComponent>
                      <DivisionComponent>
                        <h3>Description</h3>
                        <>
                          <p className="info-order">{infoConfiguration.description}</p>
                        </>
                      </DivisionComponent>
                      <DivisionComponent>
                        <h3>Details</h3>
                        <>
                          <AceEditor
                            placeholder="Message details"
                            mode="json"
                            theme="github"
                            name="message-detail"
                            onChange={onChangeMessageDetail}
                            fontSize={14}
                            showPrintMargin={false}
                            showGutter={true}
                            value={messageDetail}
                            readOnly={true}
                            setOptions={{
                              enableBasicAutocompletion: true,
                              enableLiveAutocompletion: true,
                              enableSnippets: true,
                              showLineNumbers: true,
                              tabSize: 2,
                            }}
                            style={{
                              color: '#a5a5a7',
                              backgroundColor: '#f4f4f6',
                              width: '100%',
                            }}
                          />
                        </>
                      </DivisionComponent>
                    </Section>
                  </>
                )
                  : (
                    <NoExist
                      message='The configuration does not exist'
                    />
                  )
              }
            </>
          )
      }
    </>
  )
}

export default DetailConfiguration;