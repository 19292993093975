import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
//Styles
import 'react-toastify/dist/ReactToastify.css';
//Functions
import formatDate from "../../functions/formatDate";
import generalCallApi from '../../functions/generalCallApi';
import { getSearchParam } from '../../functions/getSearchParams';
//Hooks
import useIsMobile from '../../hooks/useIsMobile';
//Components
import BreadcrumbMenu from "../Generals/BreadcrumbMenu";
import ConfirmDelete from "../Modals/OrderManagement/ConfirmDelete";
import Loader from "../Generals/Loader";
import NoExist from "../Generals/NoExist";
import NoResultsFound from "../Generals/NoResultsFound";
import Paginator from "../Generals/Paginator";
import Search from "../Generals/Search";
import Section from "../Generals/Section";
import useGroups from "../../hooks/useGroups";
import PageHeader from "../Generals/PageHeader";

const EliminateButton = ({ ...rest }) => {
  return (
    <button
      {...rest}
      type='button'
    >
      <i className="icon-delete mr-2" />
      Eliminate
    </button>
  )
}

const DetailButton = ({ order, ...rest }) => {
  const params = useParams();
  const { section, queue } = params;

  return (
    <Link
      {...rest}
      to={`/order-management/${section}/${queue}/${order.messageId}?connection=${getSearchParam('connection')}&order-id=${order.orderId}`}
    >
      <i className="icon-details mr-2" />
      Details
    </Link>
  )
}

const OrdersList = () => {
  //States
  const { groups } = useGroups();
  // const [groups] = user.signInUserSession.accessToken.payload["cognito:groups"]
  const [allOrders, setAllOrders] = useState([]);
  const [infoQueue, setInfoQueue] = useState(null);
  const [isEliminating, setIsEliminating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [orderToDelete, setOrderToDelete] = useState(null);
  const [paginatedData, setPaginatedData] = useState([]);
  //Hooks
  const params = useParams();
  const { section, queue } = params;
  //Custom hooks
  const { isDesktop } = useIsMobile();

  useEffect(() => {
    getOrdersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, queue])

  const isOperator = () => groups?.includes('operator')

  //Get orders
  const getOrdersList = async () => {
    setIsLoading(true);

    const connection = getSearchParam('connection');
    const data = await generalCallApi({
      pathname: `queue/orders/${connection}/${queue}`
    });

    let queueInformation = {
      name: queue,
    };

    if (!data.error) {
      queueInformation = {
        ...queueInformation,
        orders: data.data.orders
      };

      setInfoQueue(queueInformation);
      setAllOrders(data.data.orders);
    } else {
      if (data.message === "Orders Not Found") {
        queueInformation = {
          ...queueInformation,
          orders: []
        };
        setInfoQueue(queueInformation);
        setAllOrders([]);
        setMessage(data.message);
      } else {
        setMessage(data.message);
      }
    }

    setIsLoading(false);
  }

  //Update paginated data
  const updatePaginatedData = (value) => {
    setPaginatedData(value);
  }

  //Update order id
  const updateOrderId = (id) => {
    setOrderToDelete(id);
  }

  //Cancel eliminate action
  const cancelAction = (id) => {
    setOrderToDelete(null);
  }

  //Eliminate order
  const eliminateOrder = async () => {
    setIsEliminating(true);

    const connection = getSearchParam('connection');
    const data = await generalCallApi({
      pathname: `order/${connection}/${queue}/${orderToDelete.messageId}`,
      method: 'DELETE'
    });

    setIsEliminating(false);
    if (!data.error) {
      toast.success(data.data.message, JSON.parse(process.env.REACT_APP_TOASTIFY));
      getOrdersList();
    } else {
      toast.error(data.message, JSON.parse(process.env.REACT_APP_TOASTIFY));
    }

    setOrderToDelete(null);
  }

  return (
    <>
      <BreadcrumbMenu
        menu={[
          { label: section },
          { label: queue, param: 'connection' },
        ]}
        section='/order-management'
      />
      <PageHeader>
        <div>
          <h1 className="mb-0">Queue List Orders </h1>
          <h2>
            Queue name: {queue}
          </h2>
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-end lg:items-end gap-y-4 lg:gap-0">
          <Search
            data={allOrders}
            dependency={[section, queue]}
            placeholder="Search here an order"
            property='orderToDelete'
            setData={(value) => { setInfoQueue({ ...infoQueue, orders: value }) }}
          />
        </div>
      </PageHeader>
      {
        isLoading
          ? (
            <div className="mt-8">
              <Loader />
            </div>
          )
          : (
            <>
              {
                !!infoQueue ? (
                  <>
                    <Section>
                      {
                        isDesktop ? (
                          <table className="table-fixed">
                            <thead>
                              <tr>
                                <th>Order ID</th>
                                <th>Consignee</th>
                                <th className="w-1/6">Timestamp</th>
                                <th className="w-3/12 xl:w-2/12">Operations</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                paginatedData.length !== 0
                                  ? (
                                    paginatedData.map(order => (
                                      <tr key={order.messageId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.consignee}</td>
                                        <td>{formatDate(order.timestamp)}</td>
                                        <td className="flex gap-5">
                                          {
                                            isOperator() &&
                                            <EliminateButton
                                              onClick={() => updateOrderId(order)}
                                            />
                                          }
                                          <DetailButton
                                            order={order}
                                          />
                                        </td>
                                      </tr>
                                    ))
                                  )
                                  : (
                                    <tr>
                                      <td colSpan={4} className='text-center'>
                                        {!!getSearchParam('query') ? 'No results were found' : message}
                                      </td>
                                    </tr>
                                  )
                              }
                            </tbody>
                          </table>
                        )
                          : (
                            <>
                              {
                                paginatedData.length !== 0
                                  ? (
                                    paginatedData.map(order => (
                                      <div
                                        className="card-list"
                                        key={order.messageId}
                                      >
                                        <div>
                                          <p>Order ID:</p>
                                          <p>{order.orderId}</p>
                                        </div>
                                        <div>
                                          <p>Consignee:</p>
                                          <p>{order.consignee}</p>
                                        </div>
                                        <div>
                                          <p>Timestamp:</p>
                                          <p>{formatDate(order.timestamp)}</p>
                                        </div>
                                        <div className={`grid content-center ${isOperator() ? "grid-cols-2 gap-4" : ""}`}>
                                          {
                                            isOperator() &&
                                            <EliminateButton
                                              className="button-eliminate"
                                              onClick={() => updateOrderId(order)}
                                            />
                                          }
                                          <DetailButton
                                            order={order}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  )
                                  : <NoResultsFound
                                    message={!!getSearchParam('query') ? 'No results were found' : message}
                                  />
                              }
                            </>
                          )
                      }
                      <Paginator
                        results={infoQueue.orders}
                        total={infoQueue.orders.length}
                        updatePaginatedData={updatePaginatedData}
                      />
                    </Section>
                    {
                      !!orderToDelete && (
                        <ConfirmDelete
                          cancelText='Cancel'
                          confirmMessage={`Are you sure you want to eliminate the order with ID "${orderToDelete.orderId}"?`}
                          confirmText='Eliminate'
                          isEliminating={isEliminating}
                          onCancel={cancelAction}
                          onEliminate={eliminateOrder}
                          onSuccess={cancelAction}
                        />
                      )
                    }
                  </>

                )
                  :
                  <NoExist
                    message={message}
                  >
                    <Link
                      className="button-dark"
                      to={`/order-management/${section}`}
                    >
                      Go back
                    </Link>
                  </NoExist>
              }
            </>
          )
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default OrdersList;