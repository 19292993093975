
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        [action.property]: action.value
      };
    case 'SIGN_IN':
      return {
        ...state,
        isLogin: true,
        ...action.value,
      }
    case 'SIGN_OUT':
      return {
        isLogin: false,
        password_required: false,
        profile: null,
      };
    default:
      return state;
  }
}

export default reducer;
