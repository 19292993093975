import { Auth } from 'aws-amplify';

const generalCallApi = async ({ pathname, properties = {}, method = 'GET' }) => {

  const config = method === 'GET'
    ? {}
    : { body: JSON.stringify(properties) }

  try {
    let authToken = await Auth.currentSession();
    authToken = authToken.getIdToken().getJwtToken();

    const results = await fetch(`${process.env.REACT_APP_API_URL}${pathname}`, {
      method: method,
      headers: {
        Authorization: authToken,
      },
      ...config
    })
      .then(async (response) => {
        let res = {
          error: response.status !== 200,
          status: response.status
        }
        if (response.status === 200) {
          res = {
            ...res,
            data: await response.json(),
          }
        }
        else {
          const message = await response.json()
          res = {
            ...res,
            ...message,
          }
        }

        return res;
      });

    return results;
  } catch (error) {
    console.error(error);
    return {
      error: true,
      message: error
    };
  }
};

export default generalCallApi;