const dataConfiguration = [
  {
    "id": "630657c9f6230270e810defd",
    "name": "Charmaine",
    "description": "Qui do aliqua sunt fugiat eiusmod in irure Lorem sit culpa."
  },
  {
    "id": "630657c9a9aa1d5c391c726c",
    "name": "Ingrid",
    "description": "Aliqua cillum nostrud sit aute exercitation irure et."
  },
  {
    "id": "630657c99a6de7fe25dabfba",
    "name": "Concetta",
    "description": "Minim sint culpa ex amet commodo pariatur est amet duis exercitation minim aliqua Lorem."
  },
  {
    "id": "630657c956218d4de9436fd2",
    "name": "Dionne",
    "description": "Commodo occaecat irure cillum nostrud labore dolore adipisicing nostrud reprehenderit reprehenderit proident."
  },
  {
    "id": "630657c97f7c77d5d899ca1f",
    "name": "Amparo",
    "description": "Occaecat velit eiusmod anim nisi ullamco adipisicing in consequat."
  },
  {
    "id": "630657c9ed8bc7c874b8a650",
    "name": "Latisha",
    "description": "Cupidatat sint proident nostrud ut amet est ad veniam ipsum quis sunt."
  },
  {
    "id": "630657c95fc522b93b3d2ea3",
    "name": "Erma",
    "description": "Duis officia quis labore reprehenderit reprehenderit ea ipsum id duis veniam."
  },
  {
    "id": "630657c9a54d4009e2d9935d",
    "name": "Dixon",
    "description": "Anim incididunt labore duis mollit."
  },
  {
    "id": "630657c9b201cd0310fce540",
    "name": "Holman",
    "description": "Ad nostrud amet proident aliquip proident reprehenderit pariatur."
  },
  {
    "id": "630657c9bac3ca7e4ccb0343",
    "name": "Johnnie",
    "description": "Amet et ut ad nostrud."
  },
  {
    "id": "630657c9bf488790418b3575",
    "name": "Carmela",
    "description": "Commodo minim in magna deserunt nisi excepteur nostrud ex amet et nostrud laborum consequat minim."
  },
  {
    "id": "630657c970602dc6e15e1379",
    "name": "Mercedes",
    "description": "Aliquip exercitation adipisicing irure quis aliqua cillum do dolore nisi dolore in."
  },
  {
    "id": "630657c999b0d012d1b84809",
    "name": "Richard",
    "description": "Qui et dolore ullamco reprehenderit fugiat amet voluptate sit labore cillum ullamco consequat laborum."
  },
  {
    "id": "630657c9366ac01cb48e4e32",
    "name": "Stacie",
    "description": "Ullamco dolore non cupidatat eu officia."
  },
  {
    "id": "630657c98fcb2a57b93311cc",
    "name": "Deanna",
    "description": "Cillum laborum id Lorem qui proident sint non irure."
  },
  {
    "id": "630657c951be88b7bcc2a8a2",
    "name": "Atkinson",
    "description": "In laborum adipisicing anim tempor minim esse occaecat esse."
  },
  {
    "id": "630657c9c489894c320c2410",
    "name": "Wise",
    "description": "Tempor aliqua do veniam do fugiat nisi anim magna."
  },
  {
    "id": "630657c9bb4afec72496bc64",
    "name": "Allen",
    "description": "Deserunt deserunt ipsum sit anim."
  },
  {
    "id": "630657c9f40cac2f3fa091ac",
    "name": "Ramos",
    "description": "Culpa in esse cillum ut sint do ad amet excepteur."
  },
  {
    "id": "630657c98f8d9362a341ea03",
    "name": "Duke",
    "description": "Consequat ex veniam duis consectetur incididunt consectetur esse pariatur id nostrud amet."
  },
  {
    "id": "630657c9b2b0c9669795ff9b",
    "name": "Kayla",
    "description": "Dolor anim ea non excepteur laborum aute mollit aute est in ea."
  },
  {
    "id": "630657c933c64befc4ade6ff",
    "name": "Shari",
    "description": "Quis culpa do consequat minim qui."
  },
  {
    "id": "630657c96afafb0be2ccb540",
    "name": "Vivian",
    "description": "Ea consequat quis laboris id cillum eiusmod quis cillum nulla irure."
  },
  {
    "id": "630657c92b4f66126e1bfee3",
    "name": "Montgomery",
    "description": "Tempor consequat labore exercitation aliqua irure mollit culpa."
  }
]

export default dataConfiguration;